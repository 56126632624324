import React from 'react';
import RichText from 'components/RichText';
import styled from '@emotion/styled'
import dimensions from 'styles/dimensions';
import colors from 'styles/colors';

const ContentContainer = styled.div`
  position: relative;
  clear: both;
  
  > * {
    & + * {
      margin-top: 3.2rem;
    }
    
    & + p {
      margin-top: 1.6rem;
    }
  }
  
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    font-weight: 500;
  }
  
  > h1,
  > h2,
  > h3 {
    & + p {
      margin-top: 2.4rem;
    }
  }
  
  > h4,
  > h5,
  > h6 {
    & + p {
      margin-top: 0.6rem;
    }
  }
  
  a {
    display: inline;
    position: relative;
    color: inherit;
    background-image: linear-gradient(${colors.yellow500}, ${colors.yellow500});
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 100% 0.2rem;
    padding-bottom: 0.2rem;
    transition: color 0.08s ease-in-out;
    
    &:hover {
      color: ${colors.orange500};
    }
  }
  
  ul, ol {
    font-size: 1.6rem;
    padding-left: 3.2rem;
   
    li {
      position: relative;
      padding-left: 2.2rem;
      
      & + * {
        margin-top: 1.2rem;
      }
      
      &:has(.indented-list-item) {
        margin-left: 4rem;
      }
    } 
  }

  ul {
    li {
      &:before {
        content: "";
        position: absolute;
        top: 12px;
        left: -0.6rem;
        width: 0.6rem;
        height: 0.6rem;
        background-color: ${colors.orange500};
        border-radius: 50%;
        transform: translateY(-50%);
      }

      &:has(.indented-list-item) {
        &:before {
          border: 1px solid ${colors.grey600};
          background-color: transparent;
        }
      }
    }
  }
  
  ol {
    list-style: decimal;
  }
  
  .block-img {    
    img {
      display: block;
      max-width: 100%;
    }
    
    p + &,
    & + * {
      margin-top: 3.2rem;
    }
  }
  
  .embed {
    &-vimeo,
    &-youtube {
      > div {
        position: relative;
        width: 100%;
        height: 0;
        // 16:9 ratio
        padding-bottom: 56.25%;
        
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }  
    }
  }
  
  &.Content--homepage-programs {
    h2, 
    h3, 
    h4 {
      font-weight: 600;
      
      & + p {
        margin-top: 0.8rem;
      }
    }
  }

  &.Content--center {
    text-align: center;
    
    .block-img {
      img {
        margin: 0 auto;
      }
    }
  }

  &.Content--right {
    text-align: right;
    
    .block-img {
      img {
        float: right;
      }
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    > * {
      & + * {
        margin-top: 4rem;
      }
      
      & + p {
        margin-top: 2.4rem;
      }
    }
    
    > h1,
    > h2,
    > h3 {
      & + p {
        margin-top: 3.2rem;
      }
    }
    
    > h4,
    > h5,
    > h6 {
      & + p {
        margin-top: 1.6rem;
      }
    }
    
    li,
    p {
      
      line-height: 1.55;
    }
    
    p {
      font-size: 1.8rem;
    }

    ul, ol {
      li {
        font-size: 2rem;

        & + * {
          margin-top: 1.6rem;
        }

        &:has(.indented-list-item) {
          margin-left: 6rem;
        }
      }  
    }
    
    ul {
      li {
        &:before {
          top: 16px;  
        }
      }
    }
    
    .block-img {    
      p + &,
      & + * {
        margin-top: 4rem;
      }
    }
    
    &.Content--homepage-programs {
      p {
        font-size: 1.6rem;
        line-height: 1.5;
      }
      
      h2, 
      h3, 
      h4 {
        & + p {
          margin-top: 0.4rem;
        }
      }
    }
  }
`;

const Content = ({ content, variant }) => {
  return (
    <ContentContainer className={variant ? `Content--${variant}` : ''}>
      <RichText render={content}/>
    </ContentContainer>
  );
};

export default Content;
